<template>
  <div class="box-border py-16 pl-24 pr-16 bg-white sender-receiver-card">
    <div class="box-border relative flex flex-col pl-32 sender-item pb-28">
      <div class="sender-icon sender">
        <Icon name="icon_yundan_fa" class="text-24" />
      </div>
      <span class="text-18 text-main font-500">{{ addressInfo.senderName }}</span>
      <a class="pt-4 pb-4 text-16 text-primary" :href="`tel:${addressInfo.senderPhone}`">
        {{ addressInfo.senderPhone }}
      </a>
      <span class="text-16 text-muted">{{ senderAddress }}</span>

      <div v-if="showLoadedInfo">
        <div class="flex items-center mt-12 mb-8 text-14 bg-light rounded-4 px-14 py-14">
          <div class="flex flex-col items-center justify-center flex-1 van-hairline--right">
            <span class="text-muted">重量({{ weightUnitText }})</span>
            <span class="mt-4 text-main">
              {{ goodsInfo.loadingGoodsWeight | gram2weight(weightUnit) | defaultValue }}
            </span>
          </div>
          <!-- <div class="w-1 h-34 bg-muted"></div> -->
          <div class="flex flex-col items-center justify-center flex-1 van-hairline--right">
            <span class="text-muted">体积(方)</span>
            <span class="mt-4 text-main">
              {{ goodsInfo.loadingGoodsVolume | dm2volume | defaultValue }}
            </span>
          </div>
          <!-- <div class="w-1 h-34 bg-muted"></div> -->
          <div class="flex flex-col items-center justify-center flex-1">
            <span class="text-muted">件数(件)</span>
            <span class="mt-4 text-main">{{ goodsInfo.loadingGoodsNumber | defaultValue }}</span>
          </div>
        </div>
        <span class="text-14 text-muted">
          装货时间：{{ formatToDateTime(waybillInfo.loadingDateTime || 0, 'yyyy-MM-dd hh:mm') }}
        </span>

        <div class="flex mt-16">
          <div
            v-if="waybillInfo.loadingUrlList && waybillInfo.loadingUrlList.length"
            class="px-8 py-4 mr-16 bg-white border border-solid rounded-4 border-primary text-primary text-12 active:opacity-80"
            @click="handlePreviewImage(1)"
          >
            查看图片
          </div>
          <div
            v-if="canEdit"
            @click.stop="gotoLoadingViewEdit"
            class="px-8 py-4 bg-white border border-solid rounded-4 border-primary text-primary text-12 active:opacity-80"
          >
            更改装货信息
          </div>
        </div>
      </div>
      <div v-if="showLoadingButton">
        <button
          type="warning"
          class="h-40 mt-16 text-white w-96 text-16 bg-warning active:opacity-80 rounded-4"
          @click.stop="gotoLoadingView"
        >
          装货发车
        </button>
      </div>
    </div>
    <div class="box-border relative flex flex-col pl-32">
      <div class="receiver-icon receiver">
        <Icon name="icon_yundan_shou" class="text-24" />
      </div>
      <span class="text-18 text-main font-500">{{ addressInfo.receiverName }}</span>
      <a
        v-if="!!addressInfo.receiverPhone"
        class="pt-4 pb-4 text-16 text-primary"
        :href="`tel:${addressInfo.receiverPhone}`"
      >
        {{ addressInfo.receiverPhone }}
      </a>
      <span class="text-16 text-muted">{{ receiverAddress }}</span>
      <div v-if="showUnloadedInfo">
        <div class="flex items-center mt-12 mb-8 text-14 bg-light rounded-4 px-14 py-14">
          <div class="flex flex-col items-center justify-center flex-1 van-hairline--right">
            <span class="text-muted">重量({{ weightUnitText }})</span>
            <span class="mt-4 text-main">
              {{ goodsInfo.unloadingGoodsWeight | gram2weight(weightUnit) | defaultValue }}
            </span>
          </div>
          <!-- <div class="w-1 h-34 bg-muted"></div> -->
          <div class="flex flex-col items-center justify-center flex-1 van-hairline--right">
            <span class="text-muted">体积(方)</span>
            <span class="mt-4 text-main">
              {{ goodsInfo.unloadingGoodsVolume | dm2volume | defaultValue }}
            </span>
          </div>
          <!-- <div class="w-1 h-34 bg-muted"></div> -->
          <div class="flex flex-col items-center justify-center flex-1">
            <span class="text-muted">件数(方)</span>
            <span class="mt-4 text-main">
              {{ goodsInfo.unloadingGoodsNumber | defaultValue }}
            </span>
          </div>
        </div>
        <span class="text-14 text-muted">
          卸货时间：{{ formatToDateTime(waybillInfo.unloadingDateTime || 0, 'yyyy-MM-dd hh:mm') }}
        </span>
        <div class="flex mt-16">
          <div
            v-if="waybillInfo.unloadingUrlList && waybillInfo.unloadingUrlList.length"
            class="px-8 py-4 mr-16 bg-white border border-solid rounded-4 border-primary text-primary text-12 active:opacity-80"
            @click="handlePreviewImage(2)"
          >
            查看图片
          </div>
          <div
            v-if="canEdit"
            @click.stop="gotoUnloadingViewEdit"
            class="px-8 py-4 bg-white border border-solid rounded-4 border-primary text-primary text-12 active:opacity-80"
          >
            更改卸货信息
          </div>
        </div>
      </div>
      <div v-if="showUnLoadingButton">
        <button
          @click.stop="gotoUnloadingView"
          :disabled="!isLoadedStatus"
          type="success"
          class="h-40 mt-16 text-white w-96 leading-40 text-16 bg-success active:opacity-80 rounded-4"
        >
          卸货到达
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { WeightUnitDescMap } from '../constants';
import { gram2weight, dm2volume } from '@/utils/unit';
import { formatToDateTime } from '@wlhy-web-lib/shared';
export default {
  props: {
    waybillInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    this.formatToDateTime = formatToDateTime;
    return {};
  },
  methods: {
    gotoLoadingView() {
      this.$emit('gotoGoodsView', 0, false);
    },
    gotoLoadingViewEdit() {
      this.$emit('gotoGoodsView', 0, true);
    },
    gotoUnloadingView() {
      this.$emit('gotoGoodsView', 1, false);
    },
    gotoUnloadingViewEdit() {
      this.$emit('gotoGoodsView', 1, true);
    },
    handlePreviewImage(type) {
      this.$emit('previewImage', type);
    }
  },

  filters: {
    gram2weight(val, weightUnit) {
      return gram2weight(val, weightUnit);
    },
    dm2volume(val) {
      return dm2volume(val);
    },
    defaultValue(val, placeholder = '--') {
      if (val === '' || val === undefined || val === null) {
        return placeholder;
      }
      return val;
    }
  },

  computed: {
    addressInfo() {
      return this.waybillInfo.addressInfoDTO || {};
    },
    senderAddress() {
      return (
        (this.addressInfo.senderProvinceName || '') +
        (this.addressInfo.senderCityName || '') +
        (this.addressInfo.senderDistrictName || '') +
        (this.addressInfo.senderLocation || '')
      );
    },
    receiverAddress() {
      return (
        (this.addressInfo.receiverProvinceName || '') +
        (this.addressInfo.receiverCityName || '') +
        (this.addressInfo.receiverDistrictName || '') +
        (this.addressInfo.receiverLocation || '')
      );
    },
    goodsInfo() {
      return this.waybillInfo.goodsInfoDTO || {};
    },
    weightUnit() {
      return this.goodsInfo.weightUnit || this.waybillInfo.companyWeightUnit;
    },
    // weightUnitText() {
    //   return '吨';
    // },
    weightUnitText() {
      return WeightUnitDescMap[this.weightUnit];
    },
    showLoadingButton() {
      return this.waybillInfo.transportBillStatus < 40 && this.waybillInfo.discardStatus === 0;
    },
    showLoadedInfo() {
      return this.waybillInfo.transportBillStatus >= 40 && this.waybillInfo.discardStatus === 0;
    },
    isLoadedStatus() {
      return this.waybillInfo.transportBillStatus === 40;
    },
    showUnLoadingButton() {
      return this.waybillInfo.transportBillStatus < 50 && this.waybillInfo.discardStatus === 0;
    },
    showUnloadedInfo() {
      return this.waybillInfo.transportBillStatus >= 50 && this.waybillInfo.discardStatus === 0;
    },
    canEdit() {
      return this.waybillInfo.transportBillStatus < 60;
    }
  }
};
</script>

<style lang="less" scoped>
.sender-receiver-card {
  .bg-light {
    background-color: #f8faff;
  }

  .sender-item {
    &::before {
      position: absolute;
      top: 0.04rem;
      left: 0.12rem;
      z-index: 1;
      width: 0;
      height: 100%;
      border-right: 1px dashed #c6c6c6;
      border-right-width: 1px;
      content: ' ';
    }

    &::after {
      position: absolute;
      top: 0.1rem;
      left: 0.12rem;
      z-index: 2;
      width: 0.04rem;
      height: 100%;
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.5)),
        linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.5));
      background-position: top, bottom;
      background-repeat: no-repeat;
      background-size: 100% 30%;
      content: ' ';
    }
  }

  .receiver-icon,
  .sender-icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    &::after {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.14rem;
      color: #fff;
      flex-shrink: 0;
    }

    // &.sender {
    //   &::after {
    //     content: '发';
    //   }
    // }

    // &.receiver {
    //   &::after {
    //     content: '收';
    //   }
    // }
  }
}
</style>
