<template>
  <div class="h-full pb-12 source-detail-content bg-block">
    <!-- 导航栏 -->
    <NavBar title="运单详情"> </NavBar>

    <div class="content" v-if="info.id">
      <div class="px-20 py-16 text-white bg-primary">
        <div class="flex items-center">
          <Icon svgClass="img" name="icon_common_toast_success" />
          <span class="block ml-8 text-16 font-600">{{ statusText.label }}</span>
        </div>

        <div class="flex flex-wrap items-center justify-between">
          <span class="flex flex-shrink-0 my-8 text-14">运单号：{{ info.transportBillNumber }}</span>

          <!-- showAgainButton -->
          <div class="flex items-center">
            <van-button
              v-if="showAgainButton"
              @click="handleTakeAgain"
              type="primary"
              size="small"
              class="mr-8 opt-btn rate-btn"
              plain
            >
              再来一单
            </van-button>

            <!-- 签收后才能评论 v-if="info.transportBillStatus > 50" -->
            <van-button
              v-if="info.transportBillStatus > 50"
              type="primary"
              @click="handleRating"
              size="small"
              class="mx-8 opt-btn rate-btn"
              plain
            >
              {{ info.comment ? '查看评价' : '评价' }}
            </van-button>

            <!-- v-if="showCloseBtn" -->
            <van-button
              v-if="showCloseBtn"
              @click="handleCloseBill"
              type="primary"
              size="small"
              class="ml-8 opt-btn rate-btn"
              plain
            >
              取消接单
            </van-button>
          </div>
        </div>
      </div>

      <SenderReceiverCard @previewImage="handleImagePreview" :waybillInfo="info" @gotoGoodsView="handleToGoodsInfo" />

      <div class="box-border px-24 py-16 mt-12 bg-white text-16">
        <span class="text-18 text-main font-500">运单信息</span>
        <div class="flex items-start justify-between mt-14">
          <span class="flex-shrink-0 block text-muted">客户单号</span>
          <span class="pl-16 break-all text-main">{{ info.shipperTransportBillNumber }}</span>
        </div>
        <div class="flex items-start justify-between mt-14">
          <span class="flex-shrink-0 block text-muted">接单时间</span>
          <span class="pl-16 break-all text-main">
            {{ formatToDateTime(info.transportBillTime || 0, 'yyyy-MM-dd hh:mm') }}
          </span>
        </div>
        <div class="flex items-start justify-between mt-14">
          <span class="flex-shrink-0 block text-muted">货物名称</span>
          <span class="pl-16 break-all text-main">{{ info.goodsInfoDTO && info.goodsInfoDTO.goodsName }}</span>
        </div>
        <div class="flex items-start justify-between mt-14" v-if="info.appShowPrice !== 0">
          <span class="flex-shrink-0 block text-muted">运费</span>
          <span class="pl-16 break-all text-main">
            {{
              cent2yuan(
                info.carrierInfoDTO &&
                  info.carrierInfoDTO.carrierTransportCost &&
                  info.carrierInfoDTO.carrierTransportCost - info.carrierInfoDTO.informationPayment
              )
            }}
          </span>
        </div>
      </div>

      <!-- 撮合没有油卡付 -->
      <div class="box-border px-24 py-16 mt-12 bg-white text-16" v-if="info.appShowPrice !== 0">
        <span class="text-18 text-main font-500">运费支付情况</span>
        <div class="flex items-start justify-between mt-14">
          <span class="flex-shrink-0 block text-muted">预付</span>

          <div class="flex items-center" v-if="validatePayInfo(20200)">
            <span class="pl-16 text-main">¥ {{ payInfoMap[20200].priceNoUnit / 100 }}</span>
            <span class="pl-16 text-muted" :class="{ 'text-warning': payInfoMap[20200].status !== '待支付' }">
              {{ payInfoMap[20200].status }}
            </span>
          </div>
          <div v-else>
            --
          </div>
        </div>
        <div class="flex items-start justify-between mt-14">
          <span class="flex-shrink-0 block text-muted">到付</span>
          <div class="flex items-center" v-if="validatePayInfo(20300)">
            <span class="pl-16 text-main">¥ {{ payInfoMap[20300].priceNoUnit / 100 }}</span>
            <span class="pl-16 text-muted" :class="{ 'text-warning': payInfoMap[20300].status !== '待支付' }">
              {{ payInfoMap[20300].status }}
            </span>
          </div>
          <div v-else>
            --
          </div>
        </div>
        <div class="flex items-start justify-between mt-14">
          <span class="flex-shrink-0 block text-muted">回单付</span>
          <div class="flex items-center" v-if="validatePayInfo(20400)">
            <span class="pl-16 text-main">¥ {{ payInfoMap[20400].priceNoUnit / 100 }}</span>
            <span class="pl-16 text-muted" :class="{ 'text-warning': payInfoMap[20400].status !== '待支付' }">
              {{ payInfoMap[20400].status }}
            </span>
          </div>
          <div v-else>
            --
          </div>
        </div>
        <div class="flex items-start justify-between mt-14" v-if="info.businessScopeType !== 30">
          <span class="flex-shrink-0 block text-muted">油卡付</span>
          <div class="flex items-center" v-if="validatePayInfo(20100)">
            <span class="pl-16 text-main">¥ {{ payInfoMap[20100].priceNoUnit / 100 }}</span>
            <span class="pl-16 text-muted" :class="{ 'text-warning': payInfoMap[20100].status !== '待支付' }">
              {{ payInfoMap[20100].status }}
            </span>
          </div>
          <div v-else>
            --
          </div>
        </div>
      </div>

      <div class="box-border px-24 py-16 mt-12 bg-white text-16">
        <span class="text-18 text-main font-500">承运人信息</span>
        <div class="flex items-start justify-between mt-14">
          <span class="flex-shrink-0 block text-muted">承运人姓名</span>
          <span class="pl-16 break-all text-main">{{ $$(info.carrierInfoDTO, 'carrierName') }}</span>
        </div>
        <div class="flex items-start justify-between mt-14">
          <span class="flex-shrink-0 block text-muted">承运人电话</span>
          <span class="pl-16 break-all text-main">{{ $$(info.carrierInfoDTO, 'carrierPhone') }}</span>
        </div>
        <div class="flex items-start justify-between mt-14">
          <span class="flex-shrink-0 block text-muted">承运车辆</span>
          <span class="pl-16 break-all text-main">{{ $$(info.carrierInfoDTO, 'carLicensePlate') }}</span>
        </div>
      </div>
    </div>

    <!-- 评分弹窗 -->
    <Popup
      class="rate-box align-center px-20"
      v-model="score.visible"
      position="bottom"
      :style="{ paddingBottom: '0.32rem' }"
    >
      <p class="mt-24 mb-24 pb-20 title font-16 font-500 van-hairline--bottom text-center">评价托运方</p>
      <div class="d-flex items-start pb-20">
        <span class="font-16 mt-2" style="min-width:0.82rem">货源描述：</span>
        <div class="w-full">
          <div class="d-flex items-center">
            <Rate
              v-model="score.scoreDetail[0].score"
              :readonly="!score.submitable || info.comment"
              size="0.28rem"
              gutter="0.08rem"
              void-icon="star"
              void-color="#d8d8d8"
            ></Rate>
            <span class="font-14 ml-16 fw-500 text-warning">{{ RateLevelLabel[score.scoreDetail[0].score] }}</span>
          </div>
          <p class="font-12 pt-8 text-secondary">装卸地址是否准确，有无频繁变更地址情况</p>
        </div>
      </div>
      <div class="d-flex items-start pb-20">
        <span class="font-16 mt-2" style="min-width:0.82rem">货物装卸：</span>
        <div class="w-full">
          <div class="d-flex items-center">
            <Rate
              v-model="score.scoreDetail[1].score"
              :readonly="!score.submitable || info.comment"
              size="0.28rem"
              gutter="0.08rem"
              void-icon="star"
              void-color="#d8d8d8"
            ></Rate>
            <span class="font-14 ml-16 fw-500 text-warning">{{ RateLevelLabel[score.scoreDetail[1].score] }}</span>
          </div>
          <p class="font-12 pt-8 text-secondary">装卸货是否高效，有无压车现象</p>
        </div>
      </div>
      <div class="d-flex items-start pb-20">
        <span class="font-16 mt-2" style="min-width:0.82rem">货主沟通：</span>
        <div class="w-full">
          <div class="d-flex items-center">
            <Rate
              v-model="score.scoreDetail[2].score"
              :readonly="!score.submitable || info.comment"
              size="0.28rem"
              gutter="0.08rem"
              void-icon="star"
              void-color="#d8d8d8"
            ></Rate>
            <span class="font-14 ml-16 fw-500 text-warning">{{ RateLevelLabel[score.scoreDetail[2].score] }}</span>
          </div>
          <p class="font-12 pt-8 text-secondary">是否尊重司机，能否主动解决问题</p>
        </div>
      </div>
      <div class="d-flex items-start pb-20">
        <span class="font-16 mt-2" style="min-width:0.82rem">运费结算：</span>
        <div class="w-full">
          <div class="d-flex items-center">
            <Rate
              v-model="score.scoreDetail[3].score"
              :readonly="!score.submitable || info.comment"
              size="0.28rem"
              gutter="0.08rem"
              void-icon="star"
              void-color="#d8d8d8"
            ></Rate>
            <span class="font-14 ml-16 fw-500 text-warning">{{ RateLevelLabel[score.scoreDetail[3].score] }}</span>
          </div>
          <p class="font-12 pt-8 text-secondary">运费计算是否及时，有无克扣运费情况</p>
        </div>
      </div>

      <div class="w-full pt-8">
        <van-field
          v-model.trim="score.comments"
          class="text-14 bg-block"
          rows="3"
          type="textarea"
          maxlength="100"
          :placeholder="info.comment ? '暂无建议' : '请输入您的宝贵建议...'"
          show-word-limit
          :disabled="info.comment || !score.submitable"
        />
      </div>
      <div class="box-border w-full" v-if="!info.comment">
        <Button type="primary" class="mt-22" :disabled="!score.submitable" size="large" @click="handleScoreSubmit">
          提交评价
        </Button>
      </div>
    </Popup>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/index';
import { Button, Popup, Rate, Toast, ImagePreview, Dialog } from 'vant';
import { RateLevelLabel } from '../constants/index';
import { getBillDetail, getBillComment, billCloseApi } from '@api/waybill';
import { useRoute, useRouter } from '@/utils/compsitionHack';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { defineComponent, reactive, onBeforeMount, computed, ref, onUnmounted, watch } from '@vue/composition-api';
import useGetBtnStatus from './hooks/useBtnStatusByBillStatus';
import useMultFileTemporaryUrl from '@hook/useMultFileTemporaryUrl';
import { useConvertWeightSmallToLarge } from '@hook/useConvertWeight';
import { useConvertVolumeSmallToLarge } from '@hook/useConvertVolume';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';
import SenderReceiverCard from '../components/sender-receiver-card.vue';
import { cent2yuan } from '@/utils/unit';
import ClientApp from '@/client/index';
import { WaybillStatusDescMap, WaybillStatusMap } from '../constants';
import useTakeTheOrderAgain from './useTakeTheOrderAgain';
import { verifyBlackAPi } from '@/api/mine';

export default defineComponent({
  name: 'waybill-detail',
  components: { NavBar, Button, Popup, Rate, Toast, SenderReceiverCard },

  setup(_, ctx) {
    const route = useRoute();
    const router = useRouter();
    const path = route.path;
    // 运单id
    const id = ref(+route.query.id);
    // 运单详情
    const info = ref({});
    const addressInfoDTO = ref({});
    const carrierInfoDTO = ref({});
    const goodsInfoDTO = ref({});
    const shipperInfoDTO = ref({});
    const payInfoList = ref([]);
    const loadingUrlList = ref([]);
    const unloadingUrlList = ref([]);

    const userStore = useUserStore();

    const payInfoMap = reactive({});

    // 评分
    const score = reactive({
      value: 5, // 评分
      scoreDetail: [
        {
          score: null,
          type: 4
        },
        {
          score: null,
          type: 5
        },
        {
          score: null,
          type: 6
        },
        {
          score: null,
          type: 7
        }
      ],
      comments: '',
      visible: false, // 弹窗显示
      submitable: true // 是否可提交
    });
    watch(
      () => route.query.id,
      val => {
        if (route.path === path) {
          id.value = val;
          queryWaybillDetail();
        }
      }
    );
    onUnmounted(() => {
      ClientApp?.statusBarColor({ color: '#ffffff' });
    });

    const queryWaybillDetail = async () => {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      });

      try {
        const res = await getBillDetail({ transportBillId: id.value });
        const { data } = res;
        if (data) {
          if (data.loadingUrlList?.length) {
            data.loadingUrlList = await useMultFileTemporaryUrl(data.loadingUrlList);
          }
          if (data.unloadingUrlList?.length) {
            data.unloadingUrlList = await useMultFileTemporaryUrl(data.unloadingUrlList);
          }

          info.value = data;

          // 打分
          if (data.comment && data.transportBillDriverCommentShipperDTO) {
            score.scoreDetail =
              data.transportBillDriverCommentShipperDTO.scoreDetail &&
              data.transportBillDriverCommentShipperDTO.scoreDetail[0]
                ? data.transportBillDriverCommentShipperDTO.scoreDetail
                : {};
            score.comments = data.transportBillDriverCommentShipperDTO.comments ?? '';
          }

          addressInfoDTO.value = data.addressInfoDTO;
          carrierInfoDTO.value = data.carrierInfoDTO;
          goodsInfoDTO.value = data.goodsInfoDTO;
          shipperInfoDTO.value = data.shipperInfoDTO;
          payInfoList.value = data.payInfoList || [];
          loadingUrlList.value = data?.loadingUrlList?.slice(0, 3) || [];
          unloadingUrlList.value = data?.unloadingUrlList?.slice(0, 3) || [];

          payInfoList.value.reduce((map, item) => {
            if (!map[item.payType]) {
              map[item.payType] = item;
            }
            return map;
          }, payInfoMap);
        }

        Toast.clear();
      } catch (err) {
        console.log(err);
        Toast.fail(err);
      }
    };

    // 改变页面背景颜色
    onBeforeMount(() => {
      ClientApp?.statusBarColor({ color: '#0076FF' });
      queryWaybillDetail();
    });

    const handleRating = () => {
      score.visible = true;
    };

    // 评分提交
    const handleScoreSubmit = async () => {
      let isNoScore = false;
      score.scoreDetail.forEach(item => {
        if (!item.score) {
          isNoScore = true;
          return;
        }
      });
      if (isNoScore) {
        Toast('请先进行评价');
        return;
      }
      try {
        const res = await getBillComment({
          transportBillId: id.value,
          scoreDetail: score.scoreDetail,
          comment: score.comments
        });
        score.visible = false;
        score.submitable = false;
        Toast('提交成功！');
        queryWaybillDetail();
      } catch (err) {
        Toast.fail(err);
      }
    };

    // 跳到付确认收货/卸货页面
    const handleToGoodsInfo = async (type, isEdit = false) => {
      let params = {
        type,
        id: id.value
      };
      const oprateStatus = type === 0 ? goodsInfoDTO.value.loadingStatusId : goodsInfoDTO.value.unloadingStatusId;
      if (isEdit) {
        params = {
          isEdit,
          ...params,
          oprateStatus
        };
      }
      router.push({
        path: PageEnum.WAYBILL_GOODSINFO,
        query: params
      });
    };

    // 签约
    const handleToSign = () => {
      const id = userStore?.driverId;
      router.push({
        path: PageEnum.CONTRACT_ONLINE,
        query: { driverId: id, routerBack: 1 }
      });
    };

    const validatePayInfo = payType => {
      const item = payInfoMap[payType];
      return item;
    };

    // 取消运单
    const closeBillService = async () => {
      try {
        Toast({
          message: '运单取消中...',
          forbidClick: true
        });
        await billCloseApi({ transportBillId: id.value });
        Toast({
          message: '取消成功',
          forbidClick: true,
          type: 'success'
        });
        setTimeout(() => {
          Toast.clear(true);
          ctx.root.$routerBack.call(ctx.root._router, true);
        }, 2000);
      } catch (err) {
        Toast.fail(err);
      }
    };
    const handleCloseBill = () => {
      Dialog.confirm({
        title: '是否确认取消接单'
      })
        .then(() => {
          closeBillService();
        })
        .catch(err => {
          console.log(err);
        });
    };

    const statusText = computed(() => WaybillStatusDescMap[info.value.transportBillStatus]);

    // 取消运单按钮
    const showCloseBtn = computed(() => {
      const status = info.value.transportBillStatus;
      const recordVO = info.value.setDriverStatusOperationRecordVO;
      const shippingBillNumber = info.value.shippingBillNumber;

      // console.log(info.value);
      // 指派状态才能取消运单
      if (status === 30) {
        // "产业园0  网络货运平台1  企业[company]2  司机3"
        if (recordVO.createUserType === 3 && shippingBillNumber) {
          return true;
        }
      }
      return false;
    });

    // 再来一单
    const showAgainButton = computed(() => {
      const { shippingBillNumber, transportBillStatus, discardStatus } = info.value;
      if (shippingBillNumber && discardStatus === 0) {
        if (transportBillStatus === WaybillStatusMap.Unloaded || transportBillStatus === WaybillStatusMap.Signed) {
          return true;
        }
      }
      return false;
    });

    const takeTheOrderAgain = useTakeTheOrderAgain(id);
    const handleTakeAgain = async () => {
      try {
        const { shippingBillId, companyId } = info.value;
        if (!shippingBillId) return;

        const resDriverBlack = await verifyBlackAPi({
          type: 0,
          infoKeys: [userStore?.driverAuthVO?.idCard]
        });
        if (resDriverBlack?.data?.[0]?.isBlack) {
          Dialog.confirm({
            message: '您在当前平台黑名单中，限制开展新业务',
            showConfirmButton: false,
            cancelButtonText: '确定'
          });
          return;
        }

        takeTheOrderAgain.handleClick({ shippingBillId, companyId });
      } catch (e) {
        //
      }
    };

    return {
      info,
      addressInfoDTO,
      carrierInfoDTO,
      goodsInfoDTO,
      RateLevelLabel,
      shipperInfoDTO,
      payInfoList,
      loadingUrlList,
      unloadingUrlList,
      score,
      formatToDateTime,
      handleScoreSubmit,
      handleImagePreview: type => {
        if (type === 1) {
          ImagePreview(info.value.loadingUrlList);
        } else if (type === 2) {
          ImagePreview(info.value.unloadingUrlList);
        }
      },
      useGetBtnStatus,
      useConvertWeightSmallToLarge,
      useConvertVolumeSmallToLarge,
      handleToGoodsInfo,
      handleToSign,

      cent2yuan,
      payInfoMap,
      validatePayInfo,
      statusText,

      handleRating,

      showCloseBtn,
      handleCloseBill,

      showAgainButton,
      handleTakeAgain
    };
  }
});
</script>
<style lang="less" scoped>
// @import '../styles/detail.less';
.source-detail-content {
  /deep/ .van-nav-bar {
    @apply bg-primary !important;

    &::after {
      content: none;
    }

    .van-icon,
    .van-nav-bar__title {
      color: #fff;
    }
  }

  .content {
    margin-top: -1px;
  }

  .rate-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 0.72rem;
    height: 0.28rem;
    // padding-top: 0;
    // padding-bottom: 0;
    // line-height: 0.28rem;
    text-align: center;
    border: 0.01rem solid #fff;
    border-radius: 0.04rem;
    background: transparent;
    color: #fff;
  }
}
</style>
