const handle = (type, status) => {
  // 装货发车按钮状态
  if (type === 1) {
    // 运单状态: 已接单：30 已装货：40 已卸货：50 已签收：60
    switch (status) {
      case 30:
        return {
          disable: false,
          show: true
        };
      case 40:
        return {
          disable: true,
          show: false
        };
      case 50:
        return {
          disable: true,
          show: false
        };
      case 60:
        return {
          disable: true,
          show: false
        };
    }
    // 卸货发车按钮状态
  } else if (type === 2) {
    switch (status) {
      case 30:
        return {
          disable: true,
          show: true
        };
      case 40:
        return {
          disable: false,
          show: true
        };
      case 50:
        return {
          disable: true,
          show: false
        };
      case 60:
        return {
          disable: true,
          show: false
        };
    }
  }
};
export default handle;
