import { apiGetSourceDetail, apiCheckOrder } from '@api/source';
import { Button, Dialog, Toast } from 'vant';
import { useUserStore } from '@/store/user';
import { useRouter } from '@/utils/compsitionHack';
import { PageEnum } from '@/enums/pageEnum';
import { apiGetCarList, getPlatformAndCompanyAuditConfig } from '@api/mine';
import { useRouterQuery } from '@hook/useRouterQuery';
import ClientApp from '@/client/index';
/**
 * 再来一单逻辑
 */
export default function useTakeTheOrderAgain(id) {
  const userStore = useUserStore();
  const router = useRouter();
  const query = useRouterQuery();

  const showDriverAuthModal = () => {
    Dialog.confirm({
      message: '司机暂未认证，无法接单！',
      confirmButtonText: '点击认证',
      closeOnClickOverlay: true
    })
      .then(() => {
        router.push(PageEnum.BASE_DRIVER_AUTH);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const showDriverAuthFailModal = () => {
    const { driverId } = userStore;
    Dialog.confirm({
      message: '司机审核未通过，无法接单',
      confirmButtonText: '点击认证',
      closeOnClickOverlay: true
    })
      .then(() => {
        router.push({
          path: driverId ? PageEnum.BASE_DRIVER_AUTH_RESULT : PageEnum.BASE_DRIVER_AUTH,
          query: {
            needBack: driverId ? 1 : 0
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const showVehicleAuthModal = () => {
    Dialog.confirm({
      message: '车辆审核未通过，无法接单',
      confirmButtonText: '点击认证',
      closeOnClickOverlay: true
    })
      .then(() => {
        router.push(PageEnum.BASE_CAR_LIST);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const preCheckOrder = async ({ shippingBillId, companyId }) => {
    // 校验司机有没有订单
    const { data: checkStatus } = await apiCheckOrder({
      ...query,
      userLat: query.userLat,
      userLng: query.userLng,
      shippingBillId
    });
    if (checkStatus && checkStatus.length > 0) {
      Dialog.confirm({
        title: '提示',
        message: '您有未完成的运单，不能重复接单!',
        confirmButtonText: '查看运单',
        cancelButtonText: '好的',
        closeOnClickOverlay: false
      })
        .then(() => {
          if (checkStatus.length === 1) {
            if (id.value == checkStatus[0].id) return;
            router.push({ url: ``, query: { id: checkStatus[0].id }, replace: true });
          } else {
            ClientApp.jumpNativePage(0, 0);
          }
        })
        .catch(() => {});
      return;
    }

    const authSetting = await getPlatformAndCompanyAuditConfig({ companyId });
    const { driverAuthVO } = userStore;
    const { driverId, authStatus } = driverAuthVO;
    if (driverId) {
      if (authSetting?.data === 1 && authStatus !== 2) {
        showDriverAuthFailModal();
      } else {
        const { data: carList } = await apiGetCarList({
          driverId,
          auditStatus: authSetting?.data === 1 ? 2 : null
        });
        if (!carList?.length) {
          showVehicleAuthModal();
        } else {
          // 进入货源接单页面
          let url = `/driver/source/information?id=${shippingBillId}&actionType=100&transportBillOperateSource=5`;
          router.push(url);
        }
      }
    } else {
      showDriverAuthModal();
    }
  };

  let isLoading = false;

  const handleClick = async ({ shippingBillId, companyId }) => {
    if (isLoading) return;
    isLoading = true;
    Toast.loading({
      message: '加载中...',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0
    });
    try {
      await preCheckOrder({ shippingBillId, companyId });
      Toast.clear();
    } catch (error) {
      console.log(error);
      Toast.fail(error);
    } finally {
      isLoading = false;
    }
  };

  return {
    handleClick
  };
}
